import './DistHttps.scss'
import { useStateContext } from '../../Context/ContextProvider'
import { useMutation } from 'urql'
import { buildMutationQuery } from '../../client'
import { Link } from 'react-router-dom'
import { SubscriptionGuard } from '../SubscriptionGuard/SubscriptionGuard'

const validationStatusColor = (code) => {
  if (!code || code === 'UNKNOWN') {
    return 'F3F3F3'
  } else if (code === 'ISSUED' || code === 'SUCCESS') {
    return '#2ca02c'
  } else if (code === 'PENDING_VALIDATION' || code === 'PENDING_TRANSPARENCY_LOG') {
    return '#ff9900'
  } else return '#ff0000'
}

const DistHttps = () => {
  const { activeDist, currentDist, setReloadCurrentDist } = useStateContext()

  const domain = currentDist.domain
  const validationStatus = domain?.status || 'UNKNOWN'

  const [domainDeleteResult, domainDelete] = useMutation(
    buildMutationQuery('domainDelete', ['success'])
  )

  const handleDelete = async () => {
    const result = await domainDelete({
      input: {
        id: domain.id,
      },
    })

    if (result?.data?.domainDelete?.success) {
      setReloadCurrentDist(true)
    }
  }

  return (
    <>
      <form className="dashboard__main-form">
        <h3 className="content-subtitle">Use Your Custom Domain with HTTPS</h3>
        <p className="content-text">
          Enabling SSL/TLS adds your registered domain names to your
          distribution. Before you enable TLS/SSL, your distribution accepts
          SSL/TLS traffic for the default domain that is associated with your
          distribution when you first create it (e.g., xxx.flashedge.net).
        </p>
        <p className="content-text">
          When you want to enable SSL/TLS, you must use and verify the FlashEdge
          SSL/TLS certificate that you created for the domains that you want to
          use with your distribution.
        </p>
        <h3 className="content-subtitle">Certificate</h3>
        <p className="content-text">
          You can create SSL/TLS certificates for your domain names.
          Certificates allow you to enable custom domains and HTTPS with your
          distribution.
        </p>
        {domain && (
          <>
            <p className="content-text">
              Validation status: <span style={{color: validationStatusColor(validationStatus)}}>{validationStatus.replace('_', ' ')}</span>
            </p>
            <p className="content-text">
              Please create CNAME record from the information below in your DNS
              or follow our instructions:
            </p>
            <table className="table-wrapper" style={{maxWidth: '100%'}}>
              <thead className="table-head">
                <tr className="table-head-row">
                  <th>Name</th>
                  <th>Type</th>
                  <th>Value</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody className="table-body same-color">
                {domain.records?.map((record, index) => {
                  return (
                    <tr key={index}>
                      <td>{record.cname}</td>
                      <td>CNAME</td>
                      <td>{record.value}</td>
                      <td>{<span style={{color: validationStatusColor(record.status)}}>{record.status.replace('_', ' ')}</span>}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </>
        )}
      </form>
      {domainDeleteResult.error && (
        <p className={`content-text`}>
          <span className="warning">
            {domainDeleteResult.error.message.replace('[GraphQL] ', '')}
          </span>
        </p>
      )}
      <div className="button-wrapper">
        <SubscriptionGuard>
          <Link to={`/distributions/${activeDist}/https/create`}>
            <button className="theme-btn" type="button" disabled={!!domain}>
              Create Cerfiticate
            </button>
          </Link>
          <button
            className="theme-btn form-btn inverted"
            type="button"
            onClick={handleDelete}
            disabled={domainDeleteResult.fetching || !domain}
          >
            {domainDeleteResult.fetching && (
              <svg
                className="spinner"
                viewBox="0 0 50 50"
                width="24"
                height="24"
              >
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            )}
            Delete
          </button>
        </SubscriptionGuard>
        <a
          href="https://docs.flashedgecdn.com/flashedge-distribution-tutorials/setting-up-https-with-a-custom-domain"
          className="theme-btn ml-auto"
          type="button"
        >
          Instructions
        </a>
      </div>
    </>
  )
}

export default DistHttps
