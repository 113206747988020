import './MainNavDist.scss'

import { useStateContext } from '../../Context/ContextProvider'
import { NavLink } from 'react-router-dom'

const MainNavDist = () => {
  const { activeDist } = useStateContext()

  return (
    <div className="dashboard__main--navigation">
      <ul className="dashboard__main--navigation-list">
        <li>
          <NavLink
            to={`/distributions/${activeDist}`}
            end
            className="dashboard__main--navigation-list-item"
          >
            Overview
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/distributions/${activeDist}/origins`}
            className="dashboard__main--navigation-list-item"
          >
            Origins
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/distributions/${activeDist}/cache`}
            className="dashboard__main--navigation-list-item"
          >
            Cache
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/distributions/${activeDist}/https`}
            className="dashboard__main--navigation-list-item"
          >
            HTTPS
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/distributions/${activeDist}/monitoring`}
            className="dashboard__main--navigation-list-item"
          >
            Monitoring
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/distributions/${activeDist}/advanced`}
            className="dashboard__main--navigation-list-item"
          >
            Advanced
          </NavLink>
        </li>
      </ul>
    </div>
  )
}

export default MainNavDist
