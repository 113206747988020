import { gql, useQuery, useSubscription } from 'urql'
import { config } from '../../config'
import dummyData from '../../data'
import { buildQueryById } from '../../client'
import { useEffect, useState } from 'react'

// DistributionList
const DistributionListQuery = gql`
  query DistributionList($limit: Int, $nextToken: String) {
    distributionList(limit: $limit, nextToken: $nextToken) {
      items {
        __typename
        id
        name
        domainName
        pricingRegion
        status
        usage {
          dataIn {
            usage
            unit
            price
          }
          dataOut {
            usage
            unit
            price
          }
          euNaDataOut {
            usage
            unit
            price
          }
          restDataOut {
            usage
            unit
            price
          }
          requests {
            usage
            unit
            price
          }
          invalidations {
            usage
            unit
            price
          }
          currencyCode
          totalPrice
        }
      }
      nextToken
    }
  }
`

export const useDistributionList = () => {
  const result = useQuery({
    query: DistributionListQuery,
    pause: config.local,
  })
  if (config.local) {
    result[0] = dummyData.distributionList
  }
  return result
}

// Distribution Subscription
const DistributionOnUpdateSubscription = gql`
  subscription DistributionOnUpdate($accountId: ID!) {
    distributionOnUpdate(accountId: $accountId) {
      success
      distribution {
        __typename
        id
        name
        domainName
        pricingRegion
        status
      }
    }
  }
`

export const useDistributionSubscription = (identity, isFetching) =>
  useSubscription({
    query: DistributionOnUpdateSubscription,
    variables: {
      accountId: identity.accountId,
    },
    pause: config.local || isFetching,
  })

// Distribution
const UsageMetricFragment = ['usage', 'unit', 'price']
const DistributionByIdQuery = buildQueryById('distributionById', [
  '__typename',
  'id',
  'name',
  'domainName',
  'defaultDomainName',
  'pricingRegion',
  'status',
  {
    usage: [
      'id',
      'date',
      { dataIn: UsageMetricFragment },
      { dataOut: UsageMetricFragment },
      { euNaDataOut: UsageMetricFragment },
      { restDataOut: UsageMetricFragment },
      { requests: UsageMetricFragment },
      { invalidations: UsageMetricFragment },
      'totalPrice',
      'currencyCode',
    ],
  },
  {
    origins: [
      '__typename',
      'id',
      'storageId',
      'name',
      'domainName',
      'path',
      'protocolPolicy',
    ],
  },
  {
    domain: [
      '__typename',
      'id',
      'name',
      'status',
      { records: ['name', 'cname', 'value', 'status'] },
    ],
  },
  {
    caches: [
      '__typename',
      'id',
      'originId',
      'originName',
      'path',
      'policy',
      'compression',
      'httpRedirection',
      'httpMethods',
      { headerForwarding: ['type', 'values'] },
      { queryStringForwarding: ['type', 'values'] },
      { cookieForwarding: ['type', 'values'] },
      { objectCaching: ['minTTL', 'maxTTL', 'defaultTTL'] },
      'precedence',
    ],
  },
])

export const useDistribution = distributionId => {
  const result = useQuery({
    pause: true,
    query: DistributionByIdQuery,
    variables: {
      id: distributionId,
    },
  })
  if (config.local) {
    result[0] = dummyData.distributionById
  }
  return result
}

export const useDistributionAPI = (identity, activeDist) => {
  const [reload, setReload] = useState(false)
  const [reloadList, setReloadList] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [distributions, setDistributions] = useState([])
  const [currentDist, setCurrentDist] = useState({})

  // Fetch Distribution List
  const [listResult, reexecuteDistributionListQuery] = useDistributionList()
  // Distribution subscription
  const [subscriptionResult] = useDistributionSubscription(
    identity,
    listResult.fetching
  )
  // Fetch current distribution
  const [distributionResult, reexecuteDistributionQuery] =
    useDistribution(activeDist)

  // Fetching
  useEffect(() => {
    setFetching(listResult.fetching || distributionResult.fetching)
  }, [listResult.fetching, distributionResult.fetching])
  // Data
  const { data: queryData } = listResult
  const { data: subscriptionData } = subscriptionResult

  // Map distributions
  useEffect(() => {
    const items = queryData?.distributionList?.items || []
    const updatedItem = subscriptionData?.distributionOnUpdate?.distribution

    setDistributions(
      items.map(item => (item.id === updatedItem?.id ? updatedItem : item))
    )
  }, [
    queryData?.distributionList?.items,
    subscriptionData?.distributionOnUpdate?.distribution,
  ])

  // Trigger reload on subscription change
  useEffect(() => {
    setReload(true)
  }, [subscriptionData])

  // Reload current distribution
  // NOTE DUPLICATE?
  /*useEffect(() => {
    if (!config.local && reload) {
      reexecuteListQuery({ requestPolicy: 'network-only' })
      setReload(false)
    }
  }, [reload, reexecuteListQuery])*/

  useEffect(() => {
    if (config.local) return
    if (activeDist !== null && (reload || activeDist)) {
      reexecuteDistributionQuery({ requestPolicy: 'network-only' })
      if (reload) {
        setReload(false)
      }
    }
  }, [reload, activeDist, reexecuteDistributionQuery])

  useEffect(() => {
    if (config.local) return
    if (reloadList) {
      reexecuteDistributionListQuery({ requestPolicy: 'network-only' })
      if (reloadList) {
        setReloadList(false)
      }
    }
  }, [reloadList, reexecuteDistributionListQuery])

  // Update distribution list on distribution change
  useEffect(() => {
    const dist = distributionResult?.data?.distributionById || {}
    setCurrentDist(dist)
    if (queryData?.distributionList?.items) {
      setDistributions(
        queryData?.distributionList?.items?.map(d => {
          if (d.id === dist.id && d.status !== dist.status) {
            return {
              ...d,
              status: dist.status,
            }
          } else {
            return d
          }
        })
      )
    }
  }, [
    distributionResult?.data?.distributionById,
    queryData?.distributionList?.items,
  ])

  return {
    fetching,
    reload,
    setReload,
    setReloadList,
    distributions,
    currentDist,
  }
}
