import './DistOverview.scss'

import { useStateContext } from '../../Context/ContextProvider'
import { useMutation } from 'urql'
import { useForm } from 'react-hook-form'
import { buildMutationQuery } from '../../client'
import DistributionUsage from '../DistributionUsage/DistributionUsage'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const DistOverview = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    me,
    activeDist,
    currentDist,
    currentDistFetching,
    setReloadCurrentDist,
    setReloadDistributionList,
    isSubscription,
  } = useStateContext()
  const isTrial = me?.account?.subscription?.type === 'TRIAL'

  useEffect(() => {
    if (location.state?.reloadList) {
      delete location.state.reloadList
      setReloadDistributionList(true)
    }
  }, [location.state?.reloadList])

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm({
    values: currentDist, //data?.distributionById,
  })

  const [distributionUpdateResult, distributionUpdate] = useMutation(
    buildMutationQuery('distributionUpdate', ['success'])
  )

  const onSubmit = async input => {
    const result = await distributionUpdate({
      input: {
        id: activeDist,
        name: input.name,
        pricingRegion: input.pricingRegion,
      },
    })

    if (result?.data?.distributionUpdate?.success) {
      setReloadCurrentDist(true)
    }
  }

  return (
    <>
      <h3 className="content-subtitle mb-40">FlashEdge Distribution Domain</h3>
      {/*currentDist?.error && <p>Oh no... {currentDist.error.message}</p>*/}
      {currentDistFetching && <p>Loading...</p>}
      <div className="input-wrap">
        <p className="content-text">
          Add a CNAME record to your domain's DNS with the provided <br></br>{' '}
          domain to begin routing traffic to your FlashEdge distribution.
        </p>
        <label className="input-label" htmlFor="originDomain">
          Domain Name
        </label>
        <input
          className="input md"
          type="text"
          name="originDomain"
          id="originDomain"
          disabled
          required
          style={{fontWeight: 'bold'}}
          defaultValue={currentDist?.domainName}
        ></input>
        <label className="input-label" htmlFor="originDomain" style={{marginLeft: '6px'}}>
          <small style={{color: '#676767'}}>{ currentDist?.domainName === currentDist?.defaultDomainName ? 
            <>
              Domain name provided by FlashEdge. To use your own, <a href={`/distributions/${activeDist}/https`} style={{color: '#676767'}} onclick={e => { e.preventDefault();navigate(`/distributions/${activeDist}/https`)}} >configure SSL first</a>.
            </> : 
            <>
              Your CNAME record should be set to <a href={currentDist?.defaultDomainName} style={{color: '#676767'}} target="_blank">{currentDist?.defaultDomainName}</a>.
            </>
          }</small>
        </label>
      </div>
      <a
        href="https://docs.flashedgecdn.com/getting-started-with-flashedge/flashedge-set-up-instructions"
        className="theme-btn"
      >
        Setup Instructions
      </a>
      <h3 className="content-subtitle">Origins</h3>
      <table className="table-wrapper">
        <thead className="table-head">
          <tr className="table-head-row">
            <th>Origin Name</th>
            <th>Origin Domain</th>
            <th>Path</th>
          </tr>
        </thead>
        {currentDist?.origins && (
          <tbody className="table-body">
            {currentDist?.origins.map((origin, index) => {
              return (
                <tr key={index}>
                  <td>{origin.name}</td>
                  <td>{origin.domainName}</td>
                  <td>{origin.path}</td>
                </tr>
              )
            })}
          </tbody>
        )}
      </table>
      <form className="dashboard__main-form" onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={!isSubscription}>
          <h3 className="content-subtitle">Distribution name</h3>
          <div className="input-wrap">
            <input
              className="input"
              type="text"
              placeholder=""
              {...register('name', { required: true })}
            ></input>
          </div>

          <h3 className="content-subtitle">Pricing Regions</h3>
          <p className="content-text">
            Chose the locations where you want your data to be served. By
            choosing the right pricing region, you align content delivery with
            your audience's geography.
          </p>
          <div className="input-wrap radio-btn">
            <input
              className="input"
              id="price-region-all"
              name="pricingRegion"
              type="radio"
              value="ALL"
              required
              {...register('pricingRegion', {
                required: true,
                disabled: isTrial,
              })}
            ></input>
            <label className="input-label" htmlFor="price-region-all">
              <span className="bold">
                {isTrial ? (
                  <s>Use all FlashEdge locations (best performance)</s>
                ) : (
                  <span>Use all FlashEdge locations (best performance)</span>
                )}
              </span>
            </label>
          </div>
          <div className="input-wrap radio-btn">
            <input
              className="input"
              id="price-region-na-eu"
              name="pricingRegion"
              type="radio"
              value="NUS_EU"
              required
              {...register('pricingRegion', { required: true })}
            ></input>
            <label className="input-label" htmlFor="price-region-na-eu">
              <span className="bold">
                Use only North America and Europe (lowest cost)
              </span>
            </label>
          </div>
          <div className="input-wrap radio-btn">
            <input
              className="input"
              id="price-region-most"
              name="pricingRegion"
              type="radio"
              value="NUS_EU_ASIA_MENA"
              required
              {...register('pricingRegion', {
                required: true,
                disabled: isTrial,
              })}
            ></input>
            <label className="input-label" htmlFor="price-region-most">
              <span className="bold">
                {isTrial ? (
                  <s>
                    Use North America, Europe, Asia, Middle East, and Africa
                  </s>
                ) : (
                  <span>
                    Use North America, Europe, Asia, Middle East, and Africa
                  </span>
                )}
              </span>
            </label>
          </div>
          <br/>
          {me.account?.settings?.ws === false && <p className="content-text"><small>
          WebSocket support is disabled by default for new accounts. To enable it for your distribution, please contact our support team.
          </small></p>}
          {distributionUpdateResult.error && (
            <p className={`content-text`}>
              <span className="warning">
                {distributionUpdateResult.error.message.replace(
                  '[GraphQL] ',
                  ''
                )}
              </span>
            </p>
          )}
          <button
            className="theme-btn form-btn"
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            {isSubmitting && (
              <svg
                className="spinner"
                viewBox="0 0 50 50"
                width="24"
                height="24"
              >
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            )}
            Update Distribution
          </button>
        </fieldset>
      </form>
      {
        <>
          <h3 className="content-subtitle">Statistics</h3>
          <DistributionUsage
            name={currentDist.name}
            usage={currentDist?.usage}
          />
          <div className="chart-containers">
            {/*<LineChart DataLine={DataLine} />
        <LineChart DataLine={DataLine} />*/}
          </div>
        </>
      }
    </>
  )
}

export default DistOverview
