import { useStateContext } from '../../Context/ContextProvider'

const roundUp = (num, fx) =>
  num ? parseFloat((Math.ceil(num * 100) / 100).toFixed(fx || 2)) : 0

const StorageUsage = ({ name, usage }) => {
  const { me } = useStateContext()
  const isTrial = me?.account?.subscription?.type === 'TRIAL'
  return (
    <div className="chart-stats">
      <div className="chart-stats__col">
        {!isTrial && (
          <span className="chart-stats__price">
            ${roundUp(usage?.totalPrice)}
          </span>
        )}
        <span className="chart-stats__text">{name}</span>
      </div>
      <div className="chart-stats__col">
        <span className="chart-stats__header">Storage Usage</span>
        <span className="chart-stats__text">
          {roundUp(usage?.byteHours?.usage, 3) || 0} GB
        </span>
        {!isTrial && (
          <span className="chart-stats__text">
            ${roundUp(usage?.byteHours?.price)}
          </span>
        )}
      </div>
      <div className="chart-stats__col">
        <span className="chart-stats__header">Data Transfer Out</span>
        <span className="chart-stats__text">
          {roundUp(usage?.dto?.usage, 3)} GB
        </span>
        {!isTrial && (
          <span className="chart-stats__text">
            ${roundUp(usage?.dto?.price)}
          </span>
        )}
      </div>
      <div className="chart-stats__col"></div>
      <div className="chart-stats__col">
        <span className="chart-stats__header">Requests (Tier 1)</span>
        <span className="chart-stats__text">
          {usage?.requests1?.usage || 0}x
        </span>
        {!isTrial && (
          <span className="chart-stats__text">
            ${roundUp(usage?.requests1?.price)}
          </span>
        )}
      </div>
      <div className="chart-stats__col">
        <span className="chart-stats__header">Requests (Tier 2)</span>
        <span className="chart-stats__text">
          {usage?.requests2?.usage || 0}x
        </span>
        {!isTrial && (
          <span className="chart-stats__text">
            ${roundUp(usage?.requests2?.price)}
          </span>
        )}
      </div>
    </div>
  )
}

export default StorageUsage
