import React, { createContext, useContext, useEffect, useState } from 'react'
import { gql, useQuery } from 'urql'
import { useAuthContext } from './AuthContext'
import { config } from '../config'
import dummyData from '../data'
import { useDistributionAPI } from './ContextProvider/DistributionApi'
import { useStorageAPI } from './ContextProvider/StorageApi'

const StateContext = createContext()

const MeQuery = gql`
  query Me {
    me {
      __typename
      id
      email
      status
      firstName
      lastName
      role
      account {
        __typename
        id
        # billingEmail
        # email
        hasAccessKey
        firstName
        lastName
        street
        city
        zip
        countryCode
        # currencyCode
        companyName
        vatId
        status
        settings {
          ws
        }
        subscription {
          paymentMethod
          status
          type
          interval
          periodStart
          periodEnd
          billingAt
          endedAt
        }
      }
    }
  }
`

export const ContextProvider = ({ children }) => {
  // Managed by route handler
  const [activeDist, setActiveDist] = useState(null)
  const [activeOrigin, setActiveOrigin] = useState(null)
  const [activeCache, setActiveCache] = useState(null)
  const [activeStorage, setActiveStorage] = useState(null)

  const [spinner, setSpinner] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  // Managed by urql
  // const [currentDist, setCurrentDist] = useState({})
  // const [currentDistFetching, setCurrentDistFetching] = useState(false)
  // const [reloadCurrentDist, setReloadCurrentDist] = useState(false)
  const [reloadMe, setReloadMe] = useState(false)
  const [me, setMe] = useState({})

  // const [distributions, setDistributions] = useState([])
  // const [distributionsFetching, setDistributionsFetching] = useState(false)

  // Managed by Amplify
  const { identity } = useAuthContext()

  /* DISTRIBUTIONS */
  /*let [distributionListResult, reexecuteDistributionsQuery] =
    useDistributionList()*/

  let [meResult, reexecuteMeQuery] = useQuery({
    query: MeQuery,
    pause: config.local,
  })
  if (config.local) {
    setMe(dummyData.me)
  }
  useEffect(() => {
    setMe(meResult.data?.me)
  }, [meResult])
  const isSubscription =
    meResult.data?.me?.account?.subscription?.status === 'ACTIVE'
  const isTrial = meResult.data?.me?.account?.subscription?.type === 'TRIAL'

  const distroApi = useDistributionAPI(identity, activeDist)
  const storageApi = useStorageAPI(activeStorage)

  useEffect(() => {
    if (reloadMe) {
      reexecuteMeQuery({ requestPolicy: 'network-only' })
      setReloadMe(false)
    }
  }, [reloadMe, reexecuteMeQuery])

  useEffect(() => {
    setIsLoading(meResult.fetching || distroApi.fetching || storageApi.fetching)
  }, [meResult.fetching, distroApi.fetching, storageApi.fetching])

  
  // Current Distribution

  return (
    <StateContext.Provider
      value={{
        activeDist,
        setActiveDist,
        activeOrigin,
        setActiveOrigin,
        activeCache,
        setActiveCache,
        activeStorage,
        setActiveStorage,
        distributions: distroApi.distributions,
        currentDist: distroApi.currentDist,
        setCurrentDist: distroApi.setCurrentDist,
        currentDistFetching: distroApi.fetching,
        setReloadCurrentDist: distroApi.setReload,
        setReloadDistributionList: distroApi.setReloadList,
        //
        storageList: storageApi.storageList,
        currentStorage: storageApi.currentStorage,
        setCurrentStorage: storageApi.setCurrentStorage,
        currentStorageFetching: storageApi.fetching,
        setReloadStorage: storageApi.setReload,
        setReloadMe,
        me,
        reloadMe,
        isSubscription,
        isTrial,
        isPlan: !isTrial,
        spinner,
        setSpinner,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </StateContext.Provider>
  )
}

export const useStateContext = () => useContext(StateContext)
