import React from 'react'
import { useStateContext } from '../../Context/ContextProvider'
import { useMutation } from 'urql'
import { buildMutationQuery } from '../../client'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { SubscriptionGuard } from '../SubscriptionGuard/SubscriptionGuard'
// import PillInput from '../PillInput/PillInput'

const CreateCert = () => {
  const { activeDist, setReloadCurrentDist } = useStateContext()
  const navigate = useNavigate()

  const [domainCreateResult, domainCreate] = useMutation(
    buildMutationQuery('domainCreate', ['success'])
  )

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
  } = useForm()

  const onSubmit = async input => {
    const result = await domainCreate({
      input: {
        ...input,
        distributionId: activeDist,
      },
    })

    if (result?.data?.domainCreate?.success) {
      setReloadCurrentDist(true)
      navigate(`/distributions/${activeDist}/https`)
    }
  }

  return (
    <form className="dashboard__main-form" onSubmit={handleSubmit(onSubmit)}>
      <SubscriptionGuard type="form">
        <h3 className="content-subtitle">Create SSL/TLS Certificate</h3>
        <p className="content-text">
          Here, you can create a certificate for your custom domain
          (example.com) or any subdomain (app.example.com, ... app.example.com)
          that you want to use with the FlashEdge distribution.
        </p>
        <p className="content-text">
          When you create a certificate for example.com, you'll automatically
          receive a certificate with a wildcard for subdomains(*.example.com),
          allowing you to use your distribution with any subdomain.
        </p>
        <div className="input-wrap">
          <label className="input-label">
            Fill your primary (FQDM) domain name (example.com) or any type of
            subdomain (dev.example.com, dev.app.example.com)
          </label>
          <input
            className="input"
            type="text"
            placeholder="Fully Qualified Domain Name"
            required
            {...register('name', { required: true })}
          ></input>
        </div>
        {/*<div className='input-wrap'>
            <label className='input-label'>Fill Your Subdomain Names (www.example.com) Optional</label>
            <PillInput name="subdomain" id="subdomain"/>
      </div>*/}
        {domainCreateResult.error && (
          <p className={`content-text`}>
            <span className="warning">
              {domainCreateResult.error.message.replace('[GraphQL] ', '')}
            </span>
          </p>
        )}
        <div className="button-wrapper">
          <button
            className="theme-btn form-btn"
            type="submit"
            disabled={!isDirty || !isValid || isSubmitting}
          >
            {isSubmitting && (
              <svg
                className="spinner"
                viewBox="0 0 50 50"
                width="24"
                height="24"
              >
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            )}
            Create Certificate
          </button>
          <Link to={`/distributions/${activeDist}/https`}>
            <button className="theme-btn ml-auto-20" type="button">
              Go Back
            </button>
          </Link>
        </div>
      </SubscriptionGuard>
    </form>
  )
}

export default CreateCert
