import { gql, useQuery } from 'urql'
import PieChart from '../../Charts/PieChart'
import { buildFields } from '../../client'
import { useStateContext } from '../../Context/ContextProvider'
import { SubscriptionInfo } from '../SubscriptionInfo/SubscriptionInfo'

export const upperFirst = text => text.charAt(0).toUpperCase() + text.slice(1)

const roundUp = (num) =>
  num ? parseFloat((Math.ceil(num * 100) / 100).toFixed(2)) : 0

const usageMetric = ['usage', 'unit', 'price']
const distributionUsageFragment = gql`
  fragment DistributionCostUsageFields on DistributionCostUsageItem {
    ${buildFields([
      '__typename',
      'id',
      'distributionId',
      'date',
      { dataIn: usageMetric },
      { dataOut: usageMetric },
      { euNaDataOut: usageMetric },
      { restDataOut: usageMetric },
      { requests: usageMetric },
      { invalidations: usageMetric },
      'totalPrice',
      'currencyCode',
    ])}
  }
`

const storageUsageFragment = gql`
  fragment StorageCostUsageFields on StorageCostUsageItem {
    ${buildFields([
      '__typename',
      'id',
      'storageId',
      'date',
      { byteHours: usageMetric },
      { dataOut: usageMetric },
      { requests1: usageMetric },
      { requests2: usageMetric },
      'totalPrice',
      'currencyCode',
    ])}
  }
`

const currentCostUsageQuery = gql`
  query CurrentCostUsage {
    currentCostUsage {
      ...DistributionCostUsageFields
      ...StorageCostUsageFields
    }
  }
  ${distributionUsageFragment}
  ${storageUsageFragment}
`

const freeUsageMetric = ['usage', 'unit', 'limit']
const currentFreeUsageQuery = gql`
  query CurrentFreeUsage {
    currentFreeUsage {
      ${buildFields([
        { time: freeUsageMetric },
        { dataIn: freeUsageMetric },
        { dataOut: freeUsageMetric },
        { requests: freeUsageMetric },
        { invalidations: freeUsageMetric },
      ])}
    }
  }
`

/*const billingPortalMutation = `
  mutation BillingPortal($returnUrl: String!) {
    billingPortal(returnUrl: $returnUrl) {
      redirectUrl
    }
  }
`

const billingCheckoutMutation = `
  mutation BillingCheckout($successUrl: String!, $cancelUrl: String!) {
    billingCheckout(
      successUrl: $successUrl
      cancelUrl: $cancelUrl
    ) {
      redirectUrl
    }
  }
`*/

const usageBytes = (usage, conversion = 1_000_000_000) => ({
  usage: usage.usage / conversion,
  unit: usage.unit,
  limit: usage.limit / conversion,
})

const usageToPie = (usage, ...labels) => {
  const q = Math.ceil(usage.usage || 0)

  return [
    {
      id: 1,
      name: labels[0],
      quantity: q,
    },
    {
      id: 2,
      name: labels[1],
      quantity: usage.limit - q,
    },
  ]
}

const statusColor = status => {
  if (status === 'ACTIVE') {
    return 'green'
  } else if (['CANCELED', 'DEACTIVATED', 'EXPIRED']) {
    return 'red'
  } else return 'auto'
}

const dateFormat = dateIso => {
  const d = new Date(dateIso)
  return d.toLocaleDateString()
}

const SubscriptionDetails = () => {
  const { distributions, storageList, me } = useStateContext()

  const subscription = me?.account?.subscription

  let [freeUsageResult] = useQuery({
    query: currentFreeUsageQuery,
    pause: me?.account?.subscription?.type !== 'TRIAL',
  })

  let [costUsageResult] = useQuery({
    query: currentCostUsageQuery,
    pause: me?.account?.subscription?.type !== 'PLAN',
  })

  return (
    <>
      <div className="dashboard__main--content">
        <h2 className="billing__title">Current Status</h2>
        {subscription && (
          <>
            <p className="content-text">
              <SubscriptionInfo subscription={subscription} />
            </p>
            <p className="content-text">
              <table className="table-wrapper" style={{ textAlign: 'left' }}>
                <thead className="table-head">
                  <tr className="table-head-row">
                    <th style={{ textAlign: 'left', paddingLeft: 40 }}>
                      Subscription
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  <tr>
                    <th style={{ textAlign: 'left', paddingLeft: 40 }}>
                      Usage period:
                    </th>
                    <td style={{ textAlign: 'left' }}>
                      &nbsp;{dateFormat(subscription.periodStart)} -{' '}
                      {dateFormat(subscription.periodEnd)}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ textAlign: 'left', paddingLeft: 40 }}>
                      Billing date:
                    </th>
                    <td style={{ textAlign: 'left' }}>
                      &nbsp;
                      {dateFormat(subscription.billingAt)}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ textAlign: 'left', paddingLeft: 40 }}>
                      Billing cycle:
                    </th>
                    <td style={{ textAlign: 'left' }}>
                      &nbsp;{upperFirst(subscription.interval.toLowerCase())}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ textAlign: 'left', paddingLeft: 40 }}>
                      Type:
                    </th>
                    <td style={{ textAlign: 'left' }}>
                      &nbsp;{upperFirst(subscription.type.toLowerCase())}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ textAlign: 'left', paddingLeft: 40 }}>
                      Status:
                    </th>
                    <td
                      style={{
                        textAlign: 'left',
                        color: statusColor(subscription.status),
                      }}
                    >
                      &nbsp;{upperFirst(subscription.status.toLowerCase())}
                    </td>
                  </tr>
                </tbody>
              </table>
            </p>
          </>
        )}
        {freeUsageResult.data && (
          <>
            <h3 className="billing__subtitle">Free Trial Usage</h3>
            <div className="billing__pie-chart-wrapper">
              <div className="billing__pie-chart">
                <h4 className="billing__h4">Remaining Time [days]</h4>
                <PieChart
                  DataPie={usageToPie(
                    freeUsageResult?.data?.currentFreeUsage?.time,
                    'Used Time',
                    'Remaining Time'
                  )}
                  type="Days"
                />
              </div>
              <div className="billing__pie-chart">
                <h4 className="billing__h4">
                  Remaining data transfer out [GB]
                </h4>
                <PieChart
                  DataPie={usageToPie(
                    usageBytes(
                      freeUsageResult?.data?.currentFreeUsage?.dataOut
                    ),
                    'Used GB',
                    'Remaining GB'
                  )}
                  type="GB"
                />
              </div>
              <div className="billing__pie-chart">
                <h4 className="billing__h4">Remaining HTTP/HTTPs Request</h4>
                <PieChart
                  DataPie={usageToPie(
                    freeUsageResult?.data?.currentFreeUsage?.requests,
                    'Used Requests',
                    'Remaining Requests'
                  )}
                  type="Requests"
                />
              </div>
              <div className="billing__pie-chart">
                <h4 className="billing__h4">
                  Remaining inbound data transfer [MB]
                </h4>
                <PieChart
                  DataPie={usageToPie(
                    usageBytes(
                      freeUsageResult?.data?.currentFreeUsage?.dataIn,
                      1_000_000
                    ),
                    'Used MB',
                    'Remaining MB'
                  )}
                  type="GB"
                />
              </div>
              <div className="billing__pie-chart">
                <h4 className="billing__h4">Remaining cache invalidations</h4>
                <PieChart
                  DataPie={usageToPie(
                    freeUsageResult?.data?.currentFreeUsage?.invalidations,
                    'Used Invalidations',
                    'Remaining Invalidations'
                  )}
                  type="invalidations"
                />
              </div>
            </div>
          </>
        )}
        {costUsageResult.data && (
          <>
            <h3 className="billing__subtitle">Current Costs</h3>
            <div className="billing__pie-chart-wrapper">
              <div className="billing__pie-chart small">
                <h4 className="billing__h4">Total</h4>
                <p className="billing__price">
                  $
                  {roundUp(costUsageResult.data?.currentCostUsage?.reduce((acc, curr) => acc + curr.totalPrice, 0))}
                </p>
              </div>
              {costUsageResult.data?.currentCostUsage?.filter(usage => usage.__typename === 'DistributionCostUsageItem').map(usage => {
                return (
                  <div className="billing__pie-chart small">
                    <h4 className="billing__h4">
                      {
                        distributions.find(d => d.id === usage.distributionId)
                          ?.name
                      }
                    </h4>
                    <p className="billing__price">
                      ${roundUp(usage?.totalPrice)}
                    </p>
                    <div className="billing__flex-between">
                      <div>
                        <span>Data In &nbsp;</span>
                        <span>${roundUp(usage?.dataIn?.price)}</span>
                      </div>
                      <div>
                        <span>EU & NA &nbsp;</span>
                        <span>
                          ${roundUp(usage?.euNaDataOut?.price)}
                        </span>
                      </div>
                      <div>
                        <span>Rest &nbsp;</span>
                        <span>
                          ${roundUp(usage?.restDataOut?.price)}
                        </span>
                      </div>
                    </div>
                    <div className="billing__flex-between">
                      <div>
                        <span>Requests &nbsp;</span>
                        <span>${roundUp(usage?.requests?.price)}</span>
                      </div>
                      <div>
                        <span>Invalidations &nbsp;</span>
                        <span>
                          ${roundUp(usage?.invalidations?.price)}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })}
              {costUsageResult.data?.currentCostUsage?.filter(usage => usage.__typename === 'StorageCostUsageItem').map(usage => {
                return (
                  <div className="billing__pie-chart small">
                    <h4 className="billing__h4">
                      {
                        storageList.find(d => d.id === usage.storageId)
                          ?.name
                      }
                    </h4>
                    <p className="billing__price">
                      ${roundUp(usage?.totalPrice)}
                    </p>
                    <div className="billing__flex-between">
                      <div>
                        <span>Byte Hours  &nbsp;</span>
                        <span>${roundUp(usage?.byteHours?.price)}</span>
                      </div>
                      <div>
                        <span>Data Out  &nbsp;</span>
                        <span>
                          ${roundUp(usage?.dataOut?.price)}
                        </span>
                      </div>
                    </div>
                    <div className="billing__flex-between">
                      <div>
                        <span>Requests (Tier 1) &nbsp;</span>
                        <span>
                          ${roundUp(usage?.requests1?.price)}
                        </span>
                      </div>
                      <div>
                        <span>Requests (Tier 2) &nbsp;</span>
                        <span>
                          ${roundUp(usage?.requests2?.price)}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default SubscriptionDetails
